<template>
	<div class="app-container">	
		<div class="filter-container mt-3">
			<el-button class="filter-item float-right" :loading="buttonloading" icon="el-icon-refresh-left" @click="onClose">{{$t('button.cancel')}}</el-button>
			<el-button class="filter-item float-right" type="primary" :loading="buttonloading" icon="el-icon-document-checked" v-if="permissionList.includes(permission.DBedit)" @click="editRow">{{$t('button.save')}}</el-button>
		</div>
		
		<br/><br/>
		
		<el-form id="addForm" @submit.prevent="submit" label-width="100px" label-position="right">
			<el-tabs type="border-card" class="mt-1 mb-3">
				<el-tab-pane :label="$t('admin_general.table_general')" class="pt-4">
					<el-form-item :label="$t('admin_general.table_code')" required>
						<el-input v-model="dataList.code" :placeholder="$t('admin_general.table_code')" @change="saveData()"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_sort')" required>
						<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<!-- <el-form-item :label="$t('admin_general.table_filter')" required>
						<el-checkbox-group v-model="dataList.filter" @change="saveData()">
							<el-checkbox label="register">{{$t('admin_general.table_register')}}</el-checkbox>
							<el-checkbox label="branch">{{$t('admin_general.table_branch')}}</el-checkbox>
							<el-checkbox label="shopping">{{$t('admin_general.table_shopping')}}</el-checkbox>
							<el-checkbox label="agent">{{$t('admin_general.table_agent')}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item> -->
					
					<!-- <el-form-item :label="$t('admin_general.table_unit')">
						<el-switch v-model="dataList.is_unit" active-value="1" inactive-value="0" @change="changeUnit"> </el-switch>
					</el-form-item> -->
					
					<el-form-item :label="$t('admin_general.table_status')" required>
						<el-switch v-model="dataList.status" active-value="1" inactive-value="0" @change="saveData()"> </el-switch>
					</el-form-item>
					
					<el-tabs>
						<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
							<el-form-item :label="$t('admin_general.table_name')" required>
								<el-input v-model="dataList.name[item.id]" :disabled="loading" @change="saveData()"></el-input>
							</el-form-item>
						</el-tab-pane>
					</el-tabs>
				</el-tab-pane>
				
				<el-tab-pane :label="$t('admin_general.table_data')" class="pt-4">
					<el-form-item :label="$t('admin_general.table_brand')" required>
						<el-select v-model="dataList.brand_id" class="w-100" filterable :placeholder="$t('admin_general.table_brand')" clearable @change="saveData()">
							<el-option v-for="(item,index) in brandList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_model')" required>
						<el-select v-model="dataList.models_id" class="w-100" filterable :placeholder="$t('admin_general.table_model')" clearable @change="saveData()">
							<el-option v-for="(item,index) in modelsList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_size')" required>
						<el-select v-model="dataList.size_id" class="w-100" filterable :placeholder="$t('admin_general.table_size')" clearable @change="saveData()">
							<el-option v-for="(item,index) in sizeList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_color')" required>
						<el-select v-model="dataList.color_id" class="w-100" filterable :placeholder="$t('admin_general.table_color')" clearable @change="saveData()">
							<el-option v-for="(item,index) in colorList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<!-- <el-form-item :label="$t('admin_general.table_cost')" required>
						<el-input-number v-model="dataList.cost" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item> -->
					
					<el-form-item :label="$t('admin_general.table_retail_price')" required>
						<el-input-number v-model="dataList.retail_price" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<!-- <el-form-item :label="$t('admin_general.table_member_price')" required>
						<el-input-number v-model="dataList.member_price" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_commission_value')" required>
						<el-input-number v-model="dataList.commission_value" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_discount_percentage')" required>
						<el-input-number v-model="dataList.display_discount_rate" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_dimension')">
						<el-row>
							<el-col :xs="24" :md="8"> 
								<el-input v-model="dataList.length" :placeholder="$t('admin_general.table_length')" @change="saveData()"></el-input>
							</el-col>
							
							<el-col :xs="24" :md="8"> 
								<el-input v-model="dataList.width" :placeholder="$t('admin_general.table_width')" @change="saveData()"></el-input>
							</el-col>
							
							<el-col :xs="24" :md="8"> 
								<el-input v-model="dataList.height" :placeholder="$t('admin_general.table_height')" @change="saveData()"></el-input>
							</el-col>
						</el-row>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_length_class')">
						<el-select v-model="dataList.length_id" class="w-100" filterable :placeholder="$t('admin_general.table_length_class')" clearable @change="saveData()">
							<el-option v-for="(item,index) in lengthList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_weight')">
						<el-input-number v-model="dataList.weight" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_weight_class')">
						<el-select v-model="dataList.weight_id" class="w-100" filterable :placeholder="$t('admin_general.table_weight_class')" clearable @change="saveData()">
							<el-option v-for="(item,index) in weightList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_tax')">
						<el-input-number v-model="dataList.tax" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item>
					
					<el-form-item :label="$t('admin_general.table_tax_sst')">
						<el-input-number v-model="dataList.tax_sst" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
					</el-form-item> -->
				</el-tab-pane>
				
				<el-tab-pane :label="$t('admin_general.table_image')" class="pt-4">
					<el-card shadow="never" class="mb-4">
						<div slot="header" class="clearfix">
							<div style="margin:-18px -20px; padding:10px 20px;">
								<b><span>{{$t('admin_general.table_image')}}</span></b>
							</div>
						</div>
						
						<div style="margin:-20px; padding:20px; background:#f9f9f9;">
							<el-popover placement="right" width="130" trigger="click" class="img-thumbnail" v-model="popover">
								<el-button type="primary" icon="el-icon-edit" @click="openImage(99);"></el-button>
								<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
								
								<el-image style="width: 100px; height: 100px" fit="fill" :src="dataList.img_url" slot="reference"></el-image>
							</el-popover>
						</div>
					</el-card>
					
					
					<table class="table table-bordered table-hover">
						<thead>
							<th>{{$t('admin_general.table_additional_images')}}</th>
							<th>{{$t('admin_general.table_sort')}}</th>
							<th>{{$t('admin_general.table_action')}}</th>
						</thead>
						
						<tbody>
							<tr v-if="dataList.additional_img_url.length == 0">
								<td></td>
								<td></td>
								<td><el-button type="primary" @click="addAdditionalImage(0)" icon="el-icon-plus"></el-button></td>
							</tr>
							
							<tr v-else v-for="(input,k) in dataList.additional_img_url" :key="k">		
								<td style="vertical-align: middle;">
									<el-popover placement="right" width="130" trigger="click" class="img-thumbnail">
										<el-button type="primary" icon="el-icon-edit" @click="openImage(k);"></el-button>
										<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
										
										<el-image style="width: 100px; height: 100px" fit="fill" :src="dataList.additional_img_url[k].path" slot="reference" @change="saveData()"></el-image>
									</el-popover>
								</td>
								<td style="vertical-align: middle;">
									<el-input-number v-model="input.sort" :min="0" class="w-100" :precision="0" @change="saveData()"></el-input-number>
								</td>
								<td style="vertical-align: middle;">
									<el-button type="primary" @click="addAdditionalImage(k)" icon="el-icon-plus" v-show="k == dataList.additional_img_url.length-1 && dataList.additional_img_url.length < 4 "></el-button>
									<el-button type="danger" @click="removeAdditionalImage(k)" icon="el-icon-delete" v-show="k || ( !k && dataList.additional_img_url.length > 0)"></el-button>
								</td>
							</tr>
						</tbody>
					</table>
				</el-tab-pane>
				
				<!-- <el-tab-pane :label="$t('admin_general.table_attribute')" class="pt-4">
					<table class="table table-bordered table-hover">
						<thead>
							<th>{{$t('admin_general.table_attribute')}}</th>
							<th>{{$t('admin_general.table_value')}}</th>
							<th>{{$t('admin_general.table_action')}}</th>
						</thead>
						
						<tbody>
							<tr v-if="dataList.attribute_list.length == 0">
								<td></td>
								<td></td>
								<td><el-button type="primary" @click="addAttribute(0)" icon="el-icon-plus"></el-button></td>
							</tr>
							
							<tr v-else v-for="(input,k) in dataList.attribute_list" :key="k">		
								<td style="vertical-align: middle;">
									<el-select v-model="input.attribute_id" class="w-100" filterable :placeholder="$t('admin_general.table_attribute')" clearable @change="saveData()">
										<el-option v-for="(item,index) in bonusList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
									</el-select>
								</td>
								<td style="vertical-align: middle;">
									<el-input type="text" v-model="input.value" :placeholder="$t('admin_general.table_value')" @change="saveData()"></el-input>
								</td>
								<td style="vertical-align: middle;">
									<el-button type="primary" @click="addAttribute(k)" icon="el-icon-plus" v-show="k == dataList.attribute_list.length-1 && dataList.attribute_list.length < bonusList.length "></el-button>
									<el-button type="danger" @click="removeAttribute(k)" icon="el-icon-delete" v-show="k || ( !k && dataList.attribute_list.length > 0)"></el-button>
								</td>
							</tr>
						</tbody>
					</table>
				</el-tab-pane> -->
				
				<!-- <el-tab-pane :label="$t('admin_general.table_combo')" class="pt-4">
					<table class="table table-bordered table-hover">
						<thead>
							<th>{{$t('admin_general.table_product')}}</th>
							<th>{{$t('admin_general.table_quantity')}}</th>
							<th>{{$t('admin_general.table_free')}}</th>
							<th>{{$t('admin_general.table_action')}}</th>
						</thead>
						
						<tbody v-if="dataList.is_unit == '0'">
							<tr v-if="dataList.combo_list.length == 0 && productList.length > 0">
								<td></td>
								<td></td>
								<td></td>
								<td><el-button type="primary" @click="addCombo(0)" icon="el-icon-plus"></el-button></td>
							</tr>
							
							<tr v-else v-for="(input,k) in dataList.combo_list" :key="k">		
								<td style="vertical-align: middle;">
									<el-select v-model="input.product_id" class="w-100" filterable :placeholder="$t('admin_general.table_product')" clearable @change="saveData()">
										<el-option v-for="(item,index) in productList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
									</el-select>
								</td>
								<td style="vertical-align: middle;">
									<el-input-number v-model="input.quantity" :placeholder="$t('admin_general.table_quantity')" :min="0" class="w-100" :precision="0"  @change="saveData()"></el-input-number>
								</td>
								<td style="vertical-align: middle;">
									<el-switch v-model="input.is_free" active-value="1" inactive-value="0" @change="saveData()"> </el-switch>
								</td>
								<td style="vertical-align: middle;">
									<el-button type="primary" @click="addCombo(k)" icon="el-icon-plus" v-show="k == dataList.combo_list.length-1 && dataList.combo_list.length < productList.length "></el-button>
									<el-button type="danger" @click="removeCombo(k)" icon="el-icon-delete" v-show="k || ( !k && dataList.combo_list.length > 0)"></el-button>
								</td>
							</tr>
						</tbody>
					</table>
				</el-tab-pane> -->
				
				<!-- <el-tab-pane :label="$t('admin_general.table_rank')" class="pt-4">
					<el-form-item :label="$t('admin_general.table_mode')" required>
						<el-radio v-model="dataList.rank" label="percentage">{{$t('admin_general.table_percentage')}}</el-radio>
						<el-radio v-model="dataList.rank" label="dollar">{{$t('admin_general.table_dollar')}}</el-radio>
					</el-form-item>
					
					<el-tabs type="border-card">
						<el-tab-pane :label="$t('admin_general.table_percentage')">
							<el-table :data="dataList.rank_percentage" style="width: 100%;" border fit highlight-current-row>
								<el-table-column prop="name" :label="$t('admin_general.table_rank')" key="name" min-width="100"></el-table-column>
								<el-table-column prop="value" :label="$t('admin_general.table_percentage')" key="value" min-width="100">
									<template slot-scope="scope" >
										<el-input-number v-model="dataList.rank_percentage[scope.$index].value" :placeholder="$t('admin_general.table_dollar')" :min="0" class="w-100" :precision="2" :disabled="dataList.member_price <= 0" @change="doCalculate(scope.$index)"></el-input-number>
									</template>
								</el-table-column>
								<el-table-column prop="result" :label="$t('admin_general.table_dollar')" key="result" min-width="100">
									<template slot-scope="scope" >
										<el-input type="text" v-model="dataList.rank_percentage[scope.$index].result" :disabled="dataList.member_price <= 0" readonly @change="saveData()"></el-input>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
						
						<el-tab-pane :label="$t('admin_general.table_dollar')">
							<el-table :data="dataList.rank_dollar" style="width: 100%;" border fit highlight-current-row>
								<el-table-column prop="name" :label="$t('admin_general.table_rank')" key="name" min-width="100"></el-table-column>
								<el-table-column prop="result" :label="$t('admin_general.table_dollar')" key="dollar" min-width="100">
									<template slot-scope="scope" >
										<el-input-number v-model="dataList.rank_dollar[scope.$index].result" :placeholder="$t('admin_general.table_dollar')" :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
					</el-tabs>
				</el-tab-pane> -->

				<!-- <el-tab-pane :label="$t('admin_general.table_promotion')" class="pt-4">
					<table class="table table-bordered table-hover">
						<thead>
							<th>{{$t('general.date_range')}}</th>
							<th>{{$t('admin_general.table_promotion_price')}}</th>
							<th>{{$t('admin_general.table_action')}}</th>
						</thead>
						
						<tbody>
							<tr v-if="dataList.promotion.length == 0">
								<td></td>
								<td></td>
								<td><el-button type="primary" @click="addPromotion(0)" icon="el-icon-plus"></el-button></td>
							</tr>
							
							<tr v-else v-for="(input,k) in dataList.promotion" :key="k">		
								<td style="vertical-align: middle;">
									<el-date-picker v-model="input.date_range" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="w-100" value-format="yyyy-MM-dd" :disabled="loading" @change="saveData()"></el-date-picker>
								</td>
								<td style="vertical-align: middle;">
									<el-input-number v-model="input.price" :placeholder="$t('admin_general.table_promotion_price')"  :min="0" class="w-100" :precision="2" @change="saveData()"></el-input-number>
								</td>
								<td style="vertical-align: middle;">
									<el-button type="primary" @click="addPromotion(k)" icon="el-icon-plus" v-show="k == dataList.promotion.length-1 && dataList.promotion.length < 4 "></el-button>
									<el-button type="danger" @click="removePromotion(k)" icon="el-icon-delete" v-show="k || ( !k && dataList.promotion.length > 0)"></el-button>
								</td>
							</tr>
						</tbody>
					</table>
				</el-tab-pane> -->
			</el-tabs>
		</el-form>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
		
		<imagemanager ref="imagemanager" :isShowDialog="openImageDialog" @dialogData="closeImageDialog" @getData="getImageDialog"/>
	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import backtotop from '@/components/backtotop';
import imagemanager from '@/views/image';

let postForm = {
	code: "",
	cost: "",
	retail_price: 0,
	member_price: 0,
	commission_value: 0,
	display_discount_rate: 0,
	tax: 0,
	tax_sst: 0,
	brand_id: "",
	size_id: "",
	color_id: "",
	models_id: "",
	weight_id: "",
	length_id: "",
	weight: "",
	length: "",
	width: "",
	height: "",
	is_unit: '0',
	rank: "percentage",
	status: '0',
	sort: 100,
	rank_percentage: [],
	rank_dollar: [],
	filter:[],
	name:[],
	img_url: '',
	security: '',
	additional_img_url:[],
	attribute_list:[],
	combo_list:[],
	promotion:[]
}

export default{
	props:['id'],
	components: { backtotop, imagemanager },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			openImageDialog: false,
			languageList: [],
			permissionList: [],
			searchData:{
				id: ''
			},
			postData: {
				data: '',
				language: '',
			},
			dataList: Object.assign({}, postForm),
			defaultForm:{
				code: "",
				cost: "",
				retail_price: 0,
				member_price: 0,
				commission_value: 0,
				display_discount_rate: 0,
				tax: 0,
				tax_sst: 0,
				brand_id: "",
				size_id: "",
				color_id: "",
				models_id: "",
				weight_id: "",
				length_id: "",
				weight: "",
				length: "",
				width: "",
				height: "",
				is_unit: '0',
				rank: "percentage",
				status: '0',
				sort: 100,
				rank_percentage: [],
				rank_dollar: [],
				filter:[],
				name:[],
				img_url: '',
				security: '',
				additional_img_url:[],
				attribute_list:[],
				combo_list:[],
				promotion:[]
			
			},permission:{
                edit:'6ELC82',
                DBedit:'SIGOGO'
            },
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            },
			productList:[],
			// bonusList:[],
			tempBonusList:[],
			lengthList:[],
			weightList:[],
			brandList:[],
			sizeList:[],
			// rankList:[],
			colorList:[],
			modelsList:[],
			popover: false,
			defaultImage: ''
		}
	},methods: {
		initial(){
			if(this.permissionList.includes(this.permission.edit)){
				this.initialImage();
				this.searchData.id = this.id;
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('catalog/product/edit',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						if(data.thisDetail){
							self.defaultImage = data.defaultImg;
							self.dataList = data.thisDetail;
							self.sizeList = data.sizeList;
							self.brandList = data.brandList;
							self.colorList = data.colorList;
							self.modelsList = data.modelsList;
							self.weightList = data.weightList;
							self.lengthList = data.lengthList;
							// self.rankList = data.rankList;
							// self.bonusList = data.bonusList;
							self.productList = data.productList;
							
							if(self.dataList.weight_id == '0'){
								self.dataList.weight_id = '';
							}
							
							if(self.dataList.length_id == '0'){
								self.dataList.length_id = '';
							}
							
							self.buttonloading = false;
							self.loading = false;
						}else{
							self.onClose();
						}
					}else{
						self.onClose();
					}
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.dataList.security = value;
					this.preloader(true);
					this.buttonloading = true;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.dataList));
					var text = '';
					var self = this;
					var result = postMethod('catalog/product/DBedit',this.postData);

					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								message: data.returnMsg,
								type: 'success'
							});

							postForm = self.defaultForm;
							self.onClose();
						}else{					
							data.returnMsg.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self,text);
						}
						
						self.buttonloading = false;
						self.preloader(false);
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		
		},initialImage(){
			this.dataList.img_url = this.defaultImage;
			this.popover = false;
		},closeImageDialog(data) {
			this.openImageDialog = data;
		},openImage(index){
			this.openImageDialog = true;
			this.$refs.imagemanager.onOpen(index); 
			this.popover = false;
		},getImageDialog(data){
			if(data.index == 99){
				this.dataList.img_url = data.path;
			}else{
				this.dataList.additional_img_url[data.index].path = data.path;
			}
			this.openImageDialog = false;
		},addAdditionalImage(){
			this.dataList.additional_img_url.push({path:this.defaultImage, sort:100});
			this.saveData();
		},removeAdditionalImage(index){
			this.dataList.additional_img_url.splice(index, 1);
			this.saveData();
		},doCalculate(percentageIndex){
			var value = this.dataList.rank_percentage[percentageIndex].value;
			if(value == ''){
				value = 0;
			}
			var memberPrice = this.dataList.member_price;
			
			var total = (parseFloat(memberPrice) * (parseFloat(value) / 100)).toFixed(2);
			this.dataList.rank_percentage[percentageIndex].result = total;
			this.saveData();
		},addPromotion(){
			this.dataList.promotion.push({date_range:'', price:''});
			this.saveData();
		},removePromotion(index){
			this.dataList.promotion.splice(index, 1);
			this.saveData();
		},addAttribute(){
			this.dataList.attribute_list.push({attribute_id:'', value:''});
			this.saveData();
		},removeAttribute(index){
			this.dataList.attribute_list.splice(index, 1);
			this.saveData();
		},addCombo(){
			this.dataList.combo_list.push({product_id:'', quantity:'', is_free: '0'});
			this.saveData();
		},removeCombo(index){
			this.dataList.combo_list.splice(index, 1);
			this.saveData();
		},onClose(){
			postForm = self.defaultForm;
			this.$emit('closeCurrentTags');
		},changeUnit(){
			if(this.dataList.is_unit == '1' || this.dataList.is_unit == 1){
				this.dataList.combo_list = [];
			}
			
			this.saveData();
		},saveData(){
			postForm = this.dataList;
		}
	},created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.languageList = JSON.parse(getLocalStorage('languageList'));
		
		this.initial();
	
	}
}
</script>